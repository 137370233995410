import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAhiFxIyzEa-Qn8eIBdh2_OOJsR75CMltw",
  authDomain: "snapi-56c7d.firebaseapp.com",
  projectId: "snapi-56c7d",
  storageBucket: "snapi-56c7d.appspot.com",
  messagingSenderId: "1057402672291",
  appId: "1:1057402672291:web:ceadf07a5369534e6a9b16",
  measurementId: "G-D5GQFBSM4E",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();

export default db;
