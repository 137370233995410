import Target from "../screens/targetDevice/target";
import Capture from "../screens/captureDevice/capture";
import { BrowserRouter, Route } from "react-router-dom";
import NotFound from "../screens/NotFound/NotFound";
import { Center, Flex } from "@chakra-ui/react";

const NotFoundComponent = () => {
  return (
    <Flex justifyContent="center" h="full" alignItems="center">
      <NotFound />
    </Flex>
  );
};

function Routing() {
  return (
    <BrowserRouter>
      <Route exact path="/notFound" component={NotFoundComponent} />
      <Route exact path="/capture/:uniqueId" component={Target} />
      <Route exact path="/" component={NotFoundComponent} />
    </BrowserRouter>
  );
}

export default Routing;
