import { FC, useEffect, useState } from "react";
import {
  Box,
  Center,
  Flex,
  Spinner,
  Table,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import db from "../../../firebase";
import { ICaptureLogs } from "../../../interface/CaptureLogs.interface";
import { format, compareAsc } from "date-fns";
import AppSpinner from "../../../components/spinner/Spinner";

interface LogsProps {
  logsPath: string;
}

const Logs: FC<LogsProps> = ({ logsPath }) => {
  console.log("logsPath: ", logsPath);
  const [logs, setLogs] = useState<Array<ICaptureLogs>>([]);
  const [isLogsLoaded, setLogsLoaded] = useState<boolean>(false);

  const listenLogs = async () => {
    const doc = db.collection("card-logs").doc(logsPath);

    const observer = doc.onSnapshot(
      (docSnapshot) => {
        const data: ICaptureLogs = docSnapshot.data() as any;
        setLogsLoaded(true);
        if (logs?.length > 0) {
          setLogs((prevArray) => [...prevArray, data.logs]);
        } else {
          setLogs(data.logs);
        }
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  useEffect(() => {
    listenLogs();
  }, []);

  const getDateTimeString = (date: Date) => {
    return format(new Date(date), "MMM-dd-HH:mm:ss");
  };
  return (
    <div
      style={{
        height: "99%",
        width: "100%",
        overflow: "hidden auto",
        overflowX: "auto",
      }}
    >
      {!isLogsLoaded ? (
        <Flex height="full" justifyContent="center" alignItems="center">
          <AppSpinner size="md" />
        </Flex>
      ) : (
        <Table variant="simple" size="sm">
          <Tbody>
            <Tr borderBottomWidth="3px">
              <Td color="black">Timestamp</Td>
              <Td color="black">UniqueId</Td>
              <Td color="black">Location</Td>
              <Td color="black">Action Type</Td>
            </Tr>

            {logs &&
              logs.map((log, index) => {
                return (
                  <Tr color="black" key={index}>
                    <Td>{getDateTimeString(log.timestamp.toDate())}</Td>
                    <Td>{log.uniqueId}</Td>
                    <Td>
                      {log.address === null
                        ? "Location not allowed"
                        : log.address}
                    </Td>
                    <Td>{log.actionType}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      )}
    </div>
  );
};

export default Logs;
