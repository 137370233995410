import { PDF_LOGO } from "../../logo";
import {
  Box,
  Button,
  Center,
  Grid,
  GridItem,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from "@chakra-ui/react";
import { FC, useEffect, useState } from "react";
import jsPDF from "jspdf";
import { QRCodeSVG } from "@cheprasov/qrcode";
import * as htmlToImage from "html-to-image";
import AppSpinner from "../../components/spinner/Spinner";

interface DownloadPdfProps {
  deviceId: string;
  cardId: string;
  isActive: boolean;
  brandLogo: string;
  toClose: () => void;
}

const PdfDocument: FC<DownloadPdfProps> = ({
  isActive,
  toClose,
  deviceId,
  cardId,
  brandLogo,
}): JSX.Element => {
  const [brandLogo64, setBrandLogo64] = useState<string>("");
  const [logoHash, setLogoHash] = useState<string>("");
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const printDocument = () => {
    setIsDownloading(true);
    const input: any = document.getElementById("print");

    htmlToImage
      .toPng(input)
      .then(function (dataUrl) {
        const pdf: any = new jsPDF({
          orientation: "landscape",
          unit: "pc",
          format: [100, 100],
        });

        pdf.addImage(dataUrl, "JPEG", 5, 0);
        pdf.save("snapi-qr.pdf");
        setIsDownloading(false);
        toClose();
      })
      .catch(function (error) {
        alert("something went wrong");
        console.error("oops, something went wrong!", error);
        setIsDownloading(false);
        toClose();
      });
  };

  return (
    <>
      <Modal
        blockScrollOnMount={false}
        closeOnEsc={true}
        onEsc={() => toClose()}
        size={"3xl"}
        isOpen={isActive}
        onClose={() => {}}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download QR Code in PDF format</ModalHeader>
          <ModalCloseButton onClick={() => toClose()} />
          <ModalBody style={{ height: "400px" }}>
            <div id="print">
              <Box>
                <Center>
                  <Image src={PDF_LOGO} />
                </Center>

                <Box id="QRCode"></Box>

                <Grid templateColumns="repeat(5, 1fr)" gap={4} pl="4">
                  <GridItem colSpan={2} h="10" textAlign="start">
                    Print Date: {new Date().toDateString()}
                  </GridItem>
                  <GridItem
                    textAlign="end"
                    colStart={4}
                    colEnd={6}
                    h="10"
                    pr="4"
                  >
                    <p>All Rights Reserverd</p>
                  </GridItem>
                </Grid>
              </Box>
            </div>
          </ModalBody>
          <ModalFooter>
            {isDownloading ? (
              <Center>
                <AppSpinner size={"md"} />
              </Center>
            ) : (
              <Button
                onClick={() => printDocument()}
                style={{ backgroundColor: "#FF2C51" }}
                color="white"
                h="40px"
                w="100%"
                size="md"
              >
                Download
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PdfDocument;
