import React from "react";

class NotFound extends React.Component<{}, {}> {

  render() {
    return (
      <div >
        <div className="number">404</div>
      </div>
    );
  }
}

export default NotFound;
