import "./App.css";
import { ChakraProvider } from "@chakra-ui/react";
import Routing from "./routing/routing";
import useFetch, { Provider } from "use-http";
import { BACKEND_BASE_URL } from "./constants/constants";

function App() {
  return (
    <Provider url={BACKEND_BASE_URL}>
      <ChakraProvider>
        <Routing />
      </ChakraProvider>
    </Provider>
  );
}

export default App;
