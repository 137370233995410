import {
  Box,
  Center,
  Container,
  Flex,
  Heading,
  Link,
  Spacer,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { FC, useEffect, useState } from "react";
import {
  IoCloudDownloadSharp,
  IoQrCodeSharp,
  IoStatsChart,
} from "react-icons/io5";
import { useLocation } from "react-router";
import useFetch from "use-http";
import AppSpinner from "../../components/spinner/Spinner";
import { BACKEND_BASE_URL } from "../../constants/constants";
import db from "../../firebase";
import { KeyoneCard } from "../../interface/keyone-card.interface";
import { IRegisterDevice } from "../../interface/register-device.interface";
import { ITrackCard } from "../../interface/track-card.interface";
import NotFound from "../NotFound/NotFound";
import Logs from "./logs/logs";
import PdfDocument from "./pdfFile";
import { QRCodeSVG } from "@cheprasov/qrcode";

interface TargetProps {}

const Target: FC<TargetProps> = ({}) => {
  const [dynamic, setIsDynamic] = useState(true);
  const [hidden, setHidden] = useState(false);
  const [cardId, setCardId] = useState("");
  const [status, setStatus] = useState<boolean>(false);
  const [deviceId, setDeviceId] = useState<string>("");
  const location = useLocation();
  const [dynamicId, setDynamicId] = useState<string>("");
  const [isQRActive, setIsQRActive] = useState<boolean>(true);
  const [logsPath, setLogsPath] = useState<string>("");
  const [isLocationDenied, setIsLocationDenied] = useState<boolean>(false);
  const [date, setDate] = useState<Date>(new Date());
  const { get, post, response, loading, error } = useFetch();
  const [brandLogo, setBrandLogo] = useState<string>("");

  const registerDevice = async () => {
    const deviceId = await getDeviceId();
    const deviceLatLng = await handlePermission();
    console.log(deviceLatLng);
    if (deviceLatLng) {
      setStatus(true);
      if (!deviceId) {
        const registerDevice: IRegisterDevice = await post("/register-device", {
          lat: deviceLatLng.lat,
          long: deviceLatLng.long,
        });
        setDeviceId(registerDevice.data.deviceId);
        await localStorage.setItem("deviceId", registerDevice.data.deviceId);
        trackCard();
      }
    } else {
      setIsLocationDenied(true);
    }

    if (deviceId && deviceId.length > 0) {
      setDeviceId(deviceId);
      trackCard();
    }
  };

  const getDeviceId = async () => {
    return await localStorage.getItem("deviceId");
  };

  const trackCard = async () => {
    const dynamicId = location.pathname.split("/");
    console.log("dynamicId: ", dynamicId);

    if (dynamicId.length > 1) {
      const deviceId = await getDeviceId();
      setDeviceId(deviceId!);
      setDynamicId(dynamicId[2]);
      const trackCard: ITrackCard = await post("/track-card", {
        dynamicId: dynamicId[2],
        deviceId: deviceId,
      });

      console.log(
        "trackCard: ",
        trackCard.data.docPath,
        trackCard.data.logsPath
      );
      if (trackCard.data.docPath && trackCard.data.logsPath) {
        setLogsPath(trackCard.data.logsPath);
        listenDynamicId(trackCard.data.docPath);
      }
    }
  };

  const handlePermission = async () => {
    if (navigator.geolocation) {
      const pos: any = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      });

      return {
        long: pos.coords.longitude,
        lat: pos.coords.latitude,
      };
    } else {
      console.log("No Geolocation Service is available");
    }
  };

  const listenDynamicId = async (docPath: string) => {
    const doc = db.collection("keyone-cards").doc(docPath);

    const observer = doc.onSnapshot(
      (docSnapshot) => {
        const data: KeyoneCard = docSnapshot.data() as any;
        setBrandLogo(data.brandLogo);
        console.log("data.brandLogo: ", data.brandLogo);
        setIsDynamic(data.isDynamic);
        setDynamicId(data.dynamicCardId);
        setCardId(data.cardId);
        const divElement = document.getElementById("qrcode");

        const qrSVG = new QRCodeSVG(
          `${BACKEND_BASE_URL}/capture?deviceId=${deviceId}&dynamicId=${dynamicId}&userId=web`,
          {
            level: "Q",
            image: {
              source:
                data.brandLogo != null
                  ? data.brandLogo
                  : "https://snapi-assests.s3.eu-central-1.amazonaws.com/app-unite.png",
              width: "20%",
              height: "20%",
              x: "center",
              y: "center",
            },
          }
        );

        setTimeout(() => {
          divElement.innerHTML = qrSVG.toString();
        }, 500);
      },
      (err) => {
        console.log(`Encountered error: ${err}`);
      }
    );
  };

  useEffect(() => {
    registerDevice();
    setInterval(() => {
      setDate(new Date());
    }, 1000);
  }, []);

  return (
    <>
      {isLocationDenied ? (
        <Flex h="full" justifyContent="center" alignItems="center">
          <Heading as="h6" size="xs">
            Location Permission is required to Broadcast and view logs
          </Heading>
        </Flex>
      ) : (
        <Container
          maxW="100%"
          h="100%"
          backgroundColor="lightgray"
          margin="0"
          centerContent
          justifyContent="center"
        >
          <Container maxW="80%" h="70%" display="flex" margin="0">
            <Container
              maxW="15%"
              h="100%"
              margin="0"
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              <Stack
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Link id="qr" onClick={() => setIsQRActive(true)}>
                  <IoQrCodeSharp
                    size="50px"
                    style={{
                      textAlign: "center",
                      marginLeft: "12px",
                      color: "black",
                    }}
                  />
                  <Text color="black">Broadcast</Text>
                </Link>
                <Link id="logs" onClick={() => setIsQRActive(false)}>
                  <IoStatsChart
                    size="50px"
                    style={{ textAlign: "center", color: "black" }}
                  />
                  <Text color="black">Logs</Text>
                </Link>
                {!dynamic && (
                  <>
                    <IoCloudDownloadSharp
                      size="50px"
                      style={{ margin: "10px" }}
                    />
                    <Text
                      onClick={() => {
                        setHidden(true);
                        setTimeout(() => {
                          const divElement = document.getElementById("QRCode");

                          const qrSVG = new QRCodeSVG(
                            `https://www.snapi.com/c/${cardId}`,
                            {
                              level: "Q",
                              image: {
                                source:
                                  brandLogo.length > 0
                                    ? brandLogo
                                    : "https://snapi-assests.s3.eu-central-1.amazonaws.com/app-unite.png",
                                width: "15%",
                                height: "15%",
                                x: "center",
                                y: "center",
                              },
                            }
                          );

                          divElement.innerHTML = qrSVG.toString();
                        }, 1000);
                      }}
                    >
                      Generate QRCode PDF
                    </Text>
                  </>
                )}
              </Stack>
            </Container>
            <Container
              maxW="80%"
              h="100%"
              backgroundColor="white"
              margin="0"
              borderRadius="20px"
              centerContent
              justifyContent="center"
            >
              <Container
                maxW="100%"
                h="85%"
                centerContent
                justifyContent="center"
                p="5%"
              >
                {!status ? (
                  <>
                    <AppSpinner size={"md"} />
                    <Text>Locating location...</Text>
                  </>
                ) : !isQRActive ? (
                  logsPath.length > 1 && <Logs logsPath={logsPath} />
                ) : loading ? (
                  <>
                    <AppSpinner size={"md"} />
                    <Text>Generating QR code</Text>
                  </>
                ) : error ? (
                  <Stack alignItems="center">
                    <Center>
                      <NotFound />
                    </Center>
                    <Heading color="gray" size="sm">
                      Card id might be expired
                    </Heading>
                  </Stack>
                ) : (
                  <>
                    {dynamicId && (
                      <Box
                        id="qrcode"
                        pt="20px"
                        style={{
                          height: "420px",
                          width: "420px",
                        }}
                      ></Box>
                    )}
                  </>
                )}

                {/* <Route exact path="/logs" component={Logs} /> */}
              </Container>
              <Flex h="15%" justifyContent="space-between" alignItems="center">
                <Text
                  style={{
                    fontSize: "1em",
                    color: "dodgerblue",
                    fontWeight: "bold",
                  }}
                >
                  KeyOne
                </Text>
                <Spacer />
                <Text
                  textAlign="right"
                  style={{
                    fontSize: "1em",
                    color: "dodgerblue",
                    fontWeight: "bold",
                  }}
                >
                  {date.toLocaleString()}
                </Text>
              </Flex>
            </Container>
          </Container>
          <PdfDocument
            deviceId={deviceId}
            cardId={logsPath}
            isActive={hidden}
            brandLogo={brandLogo}
            toClose={() => setHidden(false)}
          />
        </Container>
      )}
    </>
  );
};

export default Target;
