import { FC } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import React from "react";

interface SpinnerProps {
  size: "md" | "xs" | "lg";
}

const AppSpinner: FC<SpinnerProps> = ({ size }) => {
  return (
    <Center w="full">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size={size}
      />
    </Center>
  );
};

export default AppSpinner;
